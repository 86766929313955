<template>
    <el-dialog :close-on-click-modal="false" title="新增字典" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" :model="value" label-width="80px">


            <el-form-item label="字典标题" prop="title">
                <el-input v-model="value.title" placeholder="请输入字典标题" />
            </el-form-item>


            <el-form-item label="字典标签" prop="title">
                <el-input v-model="value.title" placeholder="请输入字典标签" />
            </el-form-item>



            <el-form-item label="字典类型">
                <el-select v-model="valueSEL" placeholder="请选择字典类型">
                    <el-option v-for="item in options" :key="item.valueKEY" :label="item.label" :value="item.valueKEY">
                    </el-option>
                </el-select>
            </el-form-item>


            <el-form-item label="状态" prop="name2">
                <el-switch v-model="state" active-color="#5BD995" active-text="正常" inactive-color="#D9D9D9"
                    inactive-text="禁用" class="switchSelfBox">
                </el-switch>
            </el-form-item>



            <el-form-item label="字典值" prop="title">
                <el-input autosize type="textarea" :rows="2" placeholder="请输入字典值" v-model="value.info" />
            </el-form-item>



            <el-form-item label="字典备注" prop="title">
                <el-input autosize type="textarea" :rows="2" placeholder="请输入字典备注" v-model="value.info" />
            </el-form-item>





            <el-form-item label="显示顺序" prop="title">
                <el-input v-model="value.title" placeholder="请输入显示顺序" />
            </el-form-item>




        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,


                state: true,

                radio: '1',


                options: [{
                    valueKEY: '选项1',
                    label: '天津南开卫健委'
                }, {
                    valueKEY: '选项2',
                    label: '天津西青卫健委'
                }, ],
                valueSEL: '',



            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },


        }
    }
</script>

<style>
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择字典类型"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */
</style>